var UserFactory = angular.module('RessieApp.factories.users', [])

.factory('User', function(Users, ipCookie, $window){

  function User(obj){
    angular.extend(this, obj);
    this.useCompany = this.useCompany || this.company[0]._id;
    $window.active_user = this.name;
    $window.active_company = this.activeCompany().name;
    return this;
  }

  User.prototype.hasGroup = function(group, nth) {

    var self = this
      , hasGroup = false
      , index = "-1";

    angular.forEach(self.groups, function(item, i){
      if(item.name == group) {
        hasGroup = true;
        index = i;
      }
    });

    return (nth) ? index.toString() : hasGroup;

  };

  User.prototype.isAdmin = function(){
    return this.hasGroup('Admin');
  };

  User.prototype.Company = function(id){
    if(id) {
      var self = this;
      var cookie = ipCookie('nvbUser');
      cookie.company = id;
      this.useCompany = id;
      $window.active_company = this.activeCompany().name;
      ipCookie('nvbUser', cookie, { path: '/' });
    }
    return this.useCompany;
  };

  User.prototype.activeCompany = function(){
    var active = null;
    var self = this;
    angular.forEach(this.company, function(company){
      if(company._id == self.Company()) active = company;
    });
    active.scheme = active.scheme || 'default';
    return active;
  }

  User.prototype.hasRolesFor = function(model) {

    var self = this;

    var companyIndex = _.findIndex(self.roles, function(role){
      return role.company == self.activeCompany()._id;
    });

    var userOverrides = self.roles[companyIndex];

    var pass = false
      , bypass = false
      , pass = false;

    if(userOverrides !== undefined){

      var roles = userOverrides.rules;

      if(typeof roles !== 'undefined'
      && typeof roles[model] !== 'undefined') {
        angular.forEach(roles[model], function(allowed){
          if(allowed) pass = true;
        });
      }

      if(pass) return pass;

    }

    // Didn't find anything in the role overrides, look in security groups
    var security_groups = _.compact(_.map(self.groups, function(g){
      if(g.company == self.useCompany) return g;
    })).sort(function(a, b){
      if(a.priority > b.priority) return -1;
      if(a.priority == b.priority) return 0;
      return 1;
    });

    angular.forEach(security_groups, function Iterator(group, i){

      if(!pass){

        // Need to get the index of the model we're checking against
        var modelIndex = _.findIndex(group.models, { name: model });

        if(modelIndex >= 0){

          // if the user is in the security group, and no overrides are defined
          // (either pass or fail, regardless)
          pass = group.models[modelIndex].actions.length && (userOverrides === undefined || userOverrides.rules[model] === undefined);

          if(!pass) {
            // The user has manual overrides of the security group.
            // We need to ensure they aren't all set to false
            angular.forEach(group.models[modelIndex].actions, function(action) {
              var overridden = userOverrides.rules[model][action];
              if(overridden || overridden === undefined) pass = true;
            });
          }

        }

      }

    });

    return pass;

  }

  User.prototype.can = function(action, model){

    var self = this;

    var companyIndex = _.findIndex(self.roles, function(role){
      return role.company == self.activeCompany()._id;
    });

    var pass = false
      , bypass = false;

    if(companyIndex >= 0){

      var roles = self.roles[companyIndex].rules;

      if(typeof roles !== 'undefined'
      && typeof roles[model] !== 'undefined'
      && typeof roles[model][action] !== 'undefined') {
        return roles[model][action];
      }

    }

    var groups = _.compact(_.map(self.groups, function(g){
      if(g.company == self.useCompany) return g;
    })).sort(function(a, b){
      if(a.priority > b.priority) return -1;
      if(a.priority == b.priority) return 0;
      return 1;
    });

    self.groups = groups;

    angular.forEach(self.groups, function Iterator(group, i){

      if(!pass){

        // Need to get the index of the model we're checking against
        var modelIndex = _.findIndex(group.models, { name: model });

        if(modelIndex >= 0){
          // with that index we can now ensure that the action is present.
          var actionIndex = _.indexOf(group.models[modelIndex].actions, action);
          // Security group is missing the model assignment, but bypass is set to true
          // this means that if a model is missing it automatically passes.
          if(group.bypass && modelIndex == -1) {
            pass = group.name;
          } else if(actionIndex !== -1) {
            pass = group.name;
          }
        }

      }

    });

    return pass;

  }

  User.prototype.remove = function(){
    this.removed = true;
    this.active = false;
  }

  User.prototype.update = function(success, error) {

    var self = this;
    var data = {};

    angular.copy(self, data);

    data.groups = regroup(data.groups);

    self.saving = true;

    success = success || function(){};
    error = error || function(){};

    angular.forEach(self.roles, function(actions, model){

      angular.forEach(actions, function(value, key){
        if(/indeterminate$/.test(key)) delete self.roles[model][key];
      });

      if(_.isEmpty(actions)) delete self.roles[model];

    })

    Users.update(data,
      function Success(res){
        self.saving = false;
        success(self);
      },
      function Error(err){
        self.saving = false;
        error(self)
      }
    );

  };

  /**
   * Basically makes the group documents an array of ids if they are nested documents
   */
  function regroup(groups){
    var _groups = [];
    angular.forEach(groups, function(group, i){
      if(Object.prototype.toString.call(group) == "[object Object]"){
        _groups.push(group._id);
      } else {
        _groups.push(group);
      }
    })
  }

  return User;

})
