angular.module('RessieApp.directiveTemplates', ['dir/calendar/template', 'dir/date/date', 'dir/header/header', 'dir/input-grow/input', 'dir/reports/table-group', 'dir/reports/table', 'dir/sidebar/sidebar']);

angular.module("dir/calendar/template", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/calendar/template",
    "<div class=\"calendar\">\n" +
    "\n" +
    "  <header style=\"overflow: visible;\">\n" +
    "\n" +
    "    <h2 class=\"pull-left noselect\" style=\"margin-bottom: 20px;\">Calendar:\n" +
    "      <small style=\"margin-left: 10px;\">\n" +
    "        <span ng-click=\"prev()\"\n" +
    "        style=\"cursor: pointer; position: relative; top: -3px;\"><</span>\n" +
    "        <span class=\"dropdown\" ng-click=\"open = !open\" ng-class=\"{ 'open': open }\">\n" +
    "          <span class=\"dropdown-toggle point\">{{month}}</span>\n" +
    "          <ul class=\"dropdown-menu animated fadeIn\">\n" +
    "            <li ng-class=\"{ 'active' : month === 'January'}\">\n" +
    "              <a ng-href=\"calendar/01-01-{{ date | readTime: 'YY' }}\">January</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'February'}\">\n" +
    "              <a ng-href=\"calendar/02-01-{{ date | readTime: 'YY' }}\">February</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'March'}\">\n" +
    "              <a ng-href=\"calendar/03-01-{{ date | readTime: 'YY' }}\">March</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'April'}\">\n" +
    "              <a ng-href=\"calendar/04-01-{{ date | readTime: 'YY' }}\">April</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'May'}\">\n" +
    "              <a ng-href=\"calendar/05-01-{{ date | readTime: 'YY' }}\">May</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'June'}\">\n" +
    "              <a ng-href=\"calendar/06-01-{{ date | readTime: 'YY' }}\">June</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'July'}\">\n" +
    "              <a ng-href=\"calendar/07-01-{{ date | readTime: 'YY' }}\">July</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'August'}\">\n" +
    "              <a ng-href=\"calendar/08-01-{{ date | readTime: 'YY' }}\">August</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'September'}\">\n" +
    "              <a ng-href=\"calendar/09-01-{{ date | readTime: 'YY' }}\">September</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'October'}\">\n" +
    "              <a ng-href=\"calendar/10-01-{{ date | readTime: 'YY' }}\">October</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'November'}\">\n" +
    "              <a ng-href=\"calendar/11-01-{{ date | readTime: 'YY' }}\">November</a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"{ 'active' : month === 'December'}\">\n" +
    "              <a ng-href=\"calendar/12-01-{{ date | readTime: 'YY' }}\">December</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "        </span>\n" +
    "\n" +
    "        <span class=\"dropdown\" ng-click=\"yearOpen = !yearOpen\" ng-class=\"{ 'open': yearOpen }\">\n" +
    "          <span class=\"dropdown-toggle point\">{{ year }}</span>\n" +
    "          <ul class=\"dropdown-menu animated fadeIn\">\n" +
    "            <li ng-repeat=\"y in years track by $index\" ng-class=\"{ 'active': (y | readTime: 'YYYY') == year}\">\n" +
    "              <a ng-href=\"calendar/{{ start | readTime: 'MM' }}-01-{{ y | readTime: 'YY' }}\">{{ y | readTime: 'YYYY' }}</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "        </span>\n" +
    "\n" +
    "        <span ng-click=\"next()\"\n" +
    "        style=\"cursor: pointer; position: relative; top: -3px;\">></span>\n" +
    "      </small>\n" +
    "    </h2>\n" +
    "  </header>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-4 pull-right\">\n" +
    "\n" +
    "      <form class=\"input-group\"\n" +
    "        ng-submit=\"updateCapacity()\"\n" +
    "        style=\"width: 90px; float: right; margin-right: 10px; margin-top: 4px; margin-bottom: 10px;\">\n" +
    "        <input\n" +
    "          class=\"form-control input-sm\"\n" +
    "          ng-model=\"ranged_capacity\"\n" +
    "          type=\"number\" />\n" +
    "        <div class=\"input-group-btn\">\n" +
    "          <button\n" +
    "            type=\"submit\"\n" +
    "            class=\"btn bg-success btn-sm\">\n" +
    "            <i class=\"fa fa-check\"></i>\n" +
    "          </button>\n" +
    "        </div>\n" +
    "      </form>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"calendar-body\" style=\"margin-top: 20px;\">\n" +
    "\n" +
    "        <div class=\"week-header\">\n" +
    "          <span ng-repeat=\"day in days track by $index\" class=\"day-name\" ng-class=\"{first: $first, last: $last}\">{{day}}</span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-repeat=\"week in weeks track by $index\"\n" +
    "          class=\"week\"\n" +
    "          ng-class=\"{ first: $first, last: $last, odd: $odd, even: $even, active: isWeekActive($index+1) }\">\n" +
    "\n" +
    "          <div ng-repeat=\"day in week.days track by $index\"\n" +
    "            class=\"day\"\n" +
    "            day\n" +
    "            ng-class=\"{ 'bg-light lt' : !day, first: $first, last: $last, active: isDayActive(month, day, year), point: day, full: (dates[day].capacity.remaining <= 0), low: (dates[day].capacity.remaining <= 10 && dates[day].capacity.remaining > 0) }\">\n" +
    "\n" +
    "            <a ng-if=\"day && dates[day].capacity.remaining > 0\" ng-click=\"addDay(day)\" class=\"bg-info btn btn-bg bookNow pull-right\">\n" +
    "              <i class=\"fa fa-plus\"></i>\n" +
    "            </a>\n" +
    "\n" +
    "            <div ng-click=\"clickDay(day)\">\n" +
    "              {{ day }}&nbsp;\n" +
    "              <span class=\"capacity label-group\" ng-show=\"day\">\n" +
    "                <span ng-if=\"dates[day].capacity.group\" class=\"label label-warning\">Group</span>\n" +
    "                <span ng-if=\"dates[day].capacity.vip\" class=\"label label-info\">VIP</span>\n" +
    "                <span class=\"label label-danger\">{{ dates[day].capacity.booked || 0 }}</span>\n" +
    "\n" +
    "                <span class=\"label label-success\" ng-if=\"dates[day].capacity.remaining == 0 || dates[day].capacity.remaining\">{{ dates[day].capacity.remaining }}</span>\n" +
    "                <span class=\"label label-success\" ng-if=\"!dates[day].capacity.remaining && dates[day].capacity.remaining != 0\">{{ company.capacity }}</span>\n" +
    "              </span>\n" +
    "            </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("dir/date/date", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/date/date",
    "<input type=\"text\" ng-model=\"presentation\" ng-init=\"initialize()\"/>");
}]);

angular.module("dir/header/header", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/header/header",
    "<ul class=\"nav navbar-nav\">\n" +
    "  <li ng-repeat=\"company in user.company track by $index\" ng-class=\"{'active' : company._id == user.Company() }\">\n" +
    "    <a ng-click=\"activeCompany(company._id);\">{{ company.nickname || company.name }}</a>\n" +
    "  </li>\n" +
    "</ul>\n" +
    "\n" +
    "<ul class=\"nav navbar-nav navbar-right hidden-sm\">\n" +
    "\n" +
    "  <!-- Bug Reporter -->\n" +
    "  <li class=\"dropdown\" dropdown>\n" +
    "    <a id=\"bugTrigger\" class=\"dropdown-toggle\" dropdown-toggle href=\"#\" ng-click=\"bug.url = location.href\">\n" +
    "      <i class=\"fa fa-bug\"></i>\n" +
    "    </a>\n" +
    "    <div class=\"dropdown-menu animated fadeInDown panel panel-default\" style=\"min-width: 300px;\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h3 class=\"panel-title\">Report an Issue</h3>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\">\n" +
    "        {{ isOpen }}\n" +
    "\n" +
    "        <form class=\"form-horizontal\" ng-submit=\"reportBug(bug)\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <label for=\"bugPage\" class=\"col-sm-2 control-label\">Page</label>\n" +
    "            <div class=\"col-sm-10\">\n" +
    "              <input type=\"text\" class=\"form-control\" id=\"bugPage\" ng-model=\"bug.url\" />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"form-group\">\n" +
    "            <label for=\"inputPassword3\" class=\"col-sm-2 control-label\">Notes</label>\n" +
    "            <div class=\"col-sm-10\">\n" +
    "              <textarea class=\"form-control\"\n" +
    "                ng-model=\"bug.notes\"\n" +
    "                placeholder=\"- Steps to Reproduce\n" +
    "- Comments your actions\n" +
    "- Any other useful information\" rows=\"3\"></textarea>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <button type=\"submit\" class=\"btn btn-default pull-right\">Report</button>\n" +
    "\n" +
    "        </form>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </li>\n" +
    "\n" +
    "  <li class=\"dropdown\" dropdown>\n" +
    "    <a class=\"dropdown-toggle\" dropdown-toggle href=\"#\">\n" +
    "      </span> {{ user.name }} <b class=\"caret\"></b>\n" +
    "    </a>\n" +
    "    <ul class=\"dropdown-menu animated fadeInDown\">\n" +
    "      <li>\n" +
    "        <a ui-sref=\"me()\">User Settings</a>\n" +
    "      </li>\n" +
    "      <li ng-if=\"user.isAdmin()\">\n" +
    "        <a ui-sref=\"admin()\">Admin</a>\n" +
    "      </li>\n" +
    "      <li class=\"hide\">\n" +
    "        <a href=\"#\"> <span class=\"badge bg-danger pull-right\">3</span> Notifications </a>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <a href=\"/logout\">Logout</a>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </li>\n" +
    "</ul>\n" +
    "\n" +
    "<form role=\"search\" class=\"navbar-form navbar-right m-t-sm\" action=\"/search\" method=\"GET\">\n" +
    "  <div class=\"form-group\">\n" +
    "    <div class=\"input-group input-s\" popover-template=\"'advancedSearch.tpl.html'\" popover-title=\"Advanced Search\" popover-placement=\"bottom\">\n" +
    "      <input type=\"text\" placeholder=\"Search\" name=\"q\" ng-model=\"searchQ\" class=\"form-control input-sm no-border\" ng-class=\"{\n" +
    "			  'bg-dark': user.activeCompany().scheme === 'default',\n" +
    "			  'bg-info lter': user.activeCompany().scheme === 'blue',\n" +
    "			  'bg-danger lter': user.activeCompany().scheme === 'red',\n" +
    "			  'bg-warning lter': user.activeCompany().scheme === 'orange',\n" +
    "			  'bg-success lter': user.activeCompany().scheme === 'green'\n" +
    "			}\">\n" +
    "      <span class=\"input-group-btn\">\n" +
    "        <button class=\"btn btn-sm btn-primary btn-icon\" type=\"submit\"><i class=\"fa fa-search\"></i></button>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"advancedSearch.tpl.html\">\n" +
    "  <div>Target results with the following syntax:</div>\n" +
    "  <code><strong>name</strong>:Gabe <strong>package</strong>:Standard</code>\n" +
    "  <h6>Field Options</h6>\n" +
    "  <ul>\n" +
    "    <li>name</li>\n" +
    "    <li>email</li>\n" +
    "    <li>phone</li>\n" +
    "    <li>package</li>\n" +
    "    <li>confirmation</li>\n" +
    "  </ul>\n" +
    "</script>\n" +
    "");
}]);

angular.module("dir/input-grow/input", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/input-grow/input",
    "<input ng-attr-type=\"{{type}}\" ng-init=\"initialize()\" />");
}]);

angular.module("dir/reports/table-group", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/reports/table-group",
    "\n" +
    "<tr\n" +
    "  ng-repeat=\"(key, charge) in guest.report.all_debits | orderBy: orderCharges:reverse track by $index\"\n" +
    "  ng-if=\"(charge.total !== 0 && key !== 'total' && charge.key !== 'available') && expanded\">\n" +
    "  <td>\n" +
    "    <a ng-if=\"guest.reference.reference_model === 'Reservation'\"\n" +
    "    ng-href=\"/booking/{{guest.reference.confirmation}}\">\n" +
    "    {{ user.activeCompany().nickname }}{{ guest.reference.confirmation }}\n" +
    "    </a>\n" +
    "    <span ng-if=\"guest.reference.reference_model === 'Prepayment'\">\n" +
    "      {{ guest.reference.code }}\n" +
    "    </span>\n" +
    "  </td>\n" +
    "  <td>\n" +
    "    <span ng-if=\"guest.reference.reference_model === 'Prepayment'\">\n" +
    "      {{ guest.sort.first + ', ' + guest.sort.last }}\n" +
    "    </span>\n" +
    "    <a ng-if=\"guest.reference.reference_model === 'Reservation'\" ng-href=\"/booking/{{guest.reference.confirmation}}\">\n" +
    "      {{ guest.reference.group_name || guest.reference.last_name + ', ' + guest.reference.first_name }}\n" +
    "    </a>\n" +
    "    <strong class=\"pull-right\" ng-if=\"charge.key === 'credit_card'\">\n" +
    "      <span ng-if=\"typeof(charge.name) !== 'object'\">{{ charge.name | cardName }}: </span>\n" +
    "      <span ng-if=\"typeof(charge.name) === 'object'\">Credit Card: </span>{{ charge.masked }}</strong>\n" +
    "  </td>\n" +
    "  <td>{{ (charge.date || guest.reference.day || guest.reference.created) | readTime:'MM/DD/YY':(guest.reference === 'prepayment' ? true : false) }}</td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== 'credit_card' }\">\n" +
    "    {{ charge.key === 'credit_card' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== 'cash' }\">\n" +
    "    {{ charge.key === 'cash' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== gifts_redeemed }\">\n" +
    "    {{ charge.key === 'gifts_redeemed' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== 'deposits_redeemed' }\">\n" +
    "    {{ charge.key === 'deposits_redeemed' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== 'ar' }\">\n" +
    "    {{ charge.key === 'ar' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== 'discounts' }\">\n" +
    "    {{ charge.key === 'discounts' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.key !== 'refunds', 'text-danger': charge.key === 'refunds' && charge.total !== 0 }\">\n" +
    "    {{ charge.key === 'refunds' ? charge.total : 0 || 0 | currency }}\n" +
    "  </td>\n" +
    "  <td ng-class=\"{ 'text-muted': charge.total == 0, 'text-danger': charge.total < 0 }\">\n" +
    "    {{ charge.total || 0 | currency }}\n" +
    "  </td>\n" +
    "</tr>\n" +
    "");
}]);

angular.module("dir/reports/table", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/reports/table",
    "<div class=\"\" ng-show=\"loaded\">\n" +
    "\n" +
    "  <table class=\"table\" ng-init=\"\">\n" +
    "    <thead>\n" +
    "      <tr>\n" +
    "        <th>Items</th>\n" +
    "        <!-- Loop over days if showing multiple -->\n" +
    "        <th ng-repeat=\"(day, details) in ::days\" class=\"text-right\">\n" +
    "          <a ng-href=\"/reports/sales/{{ day | date:'MM-dd-yy' }}\" class=\"hidden-print\">\n" +
    "            {{ day | date:'MM/dd/yy' }}\n" +
    "          </a>\n" +
    "          <strong class=\"visible-print\">{{ day | date:'MM/dd/yy' }}</strong>\n" +
    "        </th>\n" +
    "        <th ng-if=\"multiDay\" class=\"text-right\">Totals</th>\n" +
    "      </tr>\n" +
    "    </thead>\n" +
    "    <!-- Payments -->\n" +
    "    <tbody>\n" +
    "\n" +
    "      <tr sales-report-group=\"charges\" period=\"!before_day\" entry-type=\"credit\">\n" +
    "        <td class=\"nowrap\">\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Payment - Credit Cards ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"cash\" period=\"on_day\" entry-type=\"credit\">\n" +
    "        <td class=\"nowrap\">\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Payment - Cash ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"bill_to\" period=\"on_day\" entry-type=\"credit\">\n" +
    "        <td class=\"nowrap\">\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Payment - Receivables ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"deposits_redeemed\" period=\"before_day\" entry-type=\"credit\">\n" +
    "        <td class=\"nowrap\">\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Payment - Deposits Redeemed ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"package\" period=\"not_cancelled\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Purchase - Balloon Flight ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"pickups\" period=\"on_day\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Purchase - Hotel Pickup ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "    </tbody>\n" +
    "\n" +
    "    <!-- Purchases -->\n" +
    "    <tbody ng-repeat=\"(item, details) in ::items['all'] track by $index\">\n" +
    "\n" +
    "      <tr sales-report-group=\"{{ item }}\" parent=\"items\" model=\"items['all']\" period=\"on_day\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Purchase - {{ item }} ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day, true)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "    </tbody>\n" +
    "\n" +
    "    <tbody>\n" +
    "\n" +
    "      <tr sales-report-group=\"prepayments\" period=\"on_day\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Purchase - Gifts ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr><td ng-attr-colspan=\"{{dayCount + 2}}\">&nbsp;</td></tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"tips\" period=\"on_day\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Tips Payable\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr><td ng-attr-colspan=\"{{dayCount + 2}}\">&nbsp;</td></tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"prepayments_redeemed\" period=\"all\" entry-type=\"credit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Gift Cards Redeemed - ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"charges\" period=\"is_deposit\" entry-type=\"debit\">\n" +
    "        <!-- entry-type=\"debit\" -->\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Deposits Recieved - ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"discounts\" period=\"all\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Discounts - ({{ all.length }})\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr><td ng-attr-colspan=\"{{dayCount + 2}}\">&nbsp;</td></tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"balance\" period=\"all\" entry-type=\"credit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Balance\n" +
    "        </td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" class=\"text-right\">\n" +
    "          {{ total = balance[day] - balance.days[day]['stripe_fees.all']; '' }}\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">\n" +
    "            {{ total | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = balance['grand'] - balance['stripe_fees.all']\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr><th ng-attr-colspan=\"{{ dayCount + 2}}\">Credit Card Settlement Calculator</th></tr>\n" +
    "\n" +
    "      <tr>\n" +
    "        <td>Payments Recieved</td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" class=\"text-right\">\n" +
    "          {{ balance.days[day]['charges.!before_day'] | currency }}\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\">\n" +
    "          {{ balance['charges.!before_day'] | currency }}\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"refunds\" period=\"!before_day\" entry-type=\"debit\">\n" +
    "        <td>\n" +
    "          <button type=\"button\" class=\"btn btn-xs btn-link\" ng-click=\"toggle()\">+</button>\n" +
    "          Refunds - ({{ all.length }})</td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" ng-init=\"total = total(day)\" class=\"text-right\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr sales-report-group=\"stripe_fees\" period=\"all\" entry-type=\"debit\">\n" +
    "        <td>Processing Fees</td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" class=\"text-right\" ng-init=\"total = total(day)\">\n" +
    "          <span ng-class=\"{ 'text-danger' : total < 0 }\">{{ total || 0 | currency }}</span>\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\" ng-init=\"this_balance = getBalance()\">\n" +
    "          <span ng-class=\"{ 'text-danger' : this_balance < 0 }\">\n" +
    "            {{ this_balance | currency }}\n" +
    "          </span>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "      <tr>\n" +
    "        <td>Settlement Amount</td>\n" +
    "        <td ng-repeat=\"(day, details) in ::days\" class=\"text-right\">\n" +
    "          {{ ( balance.days[day]['charges.!before_day'] + (balance.days[day]['refunds.!before_day'] + balance.days[day]['stripe_fees.all']) ) | currency }}\n" +
    "        </td>\n" +
    "        <td class=\"text-right\" ng-if=\"multiDay\">\n" +
    "          {{ ( balance['charges.!before_day'] + (balance['refunds.!before_day'] + balance['stripe_fees.all']) ) | currency }}\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "\n" +
    "    </tbody>\n" +
    "\n" +
    "  </table>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("dir/sidebar/sidebar", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("dir/sidebar/sidebar",
    "<section id=\"menu\" class=\"vbox\">\n" +
    "\n" +
    "  <section class=\"w-f bg-light\"  ng-class=\"\">\n" +
    "\n" +
    "    <nav class=\"nav-primary hidden-xs\">\n" +
    "\n" +
    "      <ul class=\"nav\">\n" +
    "        <li class=\"\">\n" +
    "          <a ng-click=\"showCover = false\" style=\"text-shadow: none;\" ui-sref=\"booking({ action: 'add', day: (date | readTime:'MM-DD-YY'), gift:'' })\">\n" +
    "            <i class=\"fa fa-plus\"></i>\n" +
    "            Add Booking\n" +
    "          </a>\n" +
    "        </li>\n" +
    "        <li>\n" +
    "          <a ng-click=\"showCover = false\" href=\"/calendar\">\n" +
    "            <i class=\"fa fa-calendar\"></i>\n" +
    "            Calendar\n" +
    "          </a>\n" +
    "        </li>\n" +
    "\n" +
    "        <li ng-class=\"{ 'active' : state.name == 'day' }\">\n" +
    "          <a ng-click=\"showCover = false\" ui-sref=\"day.bookings({ day: 'tomorrow' })\">\n" +
    "            <i class=\"fa fa-list-ul\"></i>\n" +
    "            Bookings\n" +
    "          </a>\n" +
    "        </li>\n" +
    "\n" +
    "        <li ng-class=\"{ 'active' : state.name == 'notifications' }\">\n" +
    "          <a ng-click=\"showCover = false\" ui-sref=\"notifications()\">\n" +
    "            <b ng-hide=\"(pendingBookings + pendingNotes + unrespondedMessages.length) == 0 \" class=\"badge bg-danger pull-right\">{{ pendingBookings + pendingNotes + unrespondedMessages.length }}</b>\n" +
    "            <i class=\"fa fa-bell\"></i>\n" +
    "            Notifications\n" +
    "          </a>\n" +
    "        </li>\n" +
    "\n" +
    "        <li ng-class=\"{ 'active' : state.name == 'gifts'}\">\n" +
    "          <a ng-click=\"showCover = false\" ui-sref=\"listPrepayments({ type: 'gift', page: 1 })\">\n" +
    "            <b ng-hide=\"pendingGifts.length == 0\" class=\"badge bg-danger pull-right\">{{ pendingGifts.length }}</b>\n" +
    "            <i class=\"fa fa-gift \"></i>\n" +
    "            Prepaid\n" +
    "          </a>\n" +
    "        </li>\n" +
    "\n" +
    "        <li ng-class=\"{ 'active' : state.name == 'contacts' }\">\n" +
    "          <a ng-click=\"showCover = false\" ui-sref=\"contacts({ tab: 'hotels', filter: 'all', page: 1 })\">\n" +
    "            <i class=\"fa fa-user\"></i>\n" +
    "            Contacts\n" +
    "          </a>\n" +
    "        </li>\n" +
    "        <li ng-class=\"{ 'active' : state.name == 'reports' }\">\n" +
    "          <a ng-click=\"showCover = false\" ng-href=\"/reports\">\n" +
    "            <i class=\"fa fa-bar-chart-o \"></i>\n" +
    "            Reports\n" +
    "          </a>\n" +
    "        </li>\n" +
    "        <li>\n" +
    "          <a ng-click=\"showCover = false\" ui-sref=\"dashboard()\">\n" +
    "            <i class=\"fa fa-dashboard\"></i>\n" +
    "            Dashboard\n" +
    "          </a>\n" +
    "        </li>\n" +
    "        <li ng-if=\"showAdmin()\">\n" +
    "          <a ng-click=\"showCover = false\" ui-sref=\"admin({ tab: '' })\">\n" +
    "            <i class=\"fa fa-cog\"></i>\n" +
    "            Settings\n" +
    "          </a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "\n" +
    "    </nav>\n" +
    "\n" +
    "  </section>\n" +
    "\n" +
    "  <footer class=\"footer bg-gradient hidden-xs\">\n" +
    "    <a ng-click=\"showCover = false\" href=\"/logout\" class=\"btn btn-sm btn-link m-r-n-xs pull-right\">\n" +
    "      <i class=\"fa fa-power-off\"></i>\n" +
    "    </a>\n" +
    "    <a ng-click=\"showCover = false\" href=\"\" ng-click=\"toggle()\" class=\"btn btn-sm btn-link m-l-n-sm\">\n" +
    "      <i class=\"fa fa-reorder\"></i>\n" +
    "    </a>\n" +
    "  </footer>\n" +
    "\n" +
    "</section>\n" +
    "");
}]);
