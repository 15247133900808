var MODULE_NAME = 'RessieApp.factories.socket';
var SocketFactory = angular.module(MODULE_NAME, [])
  .value('$stateParams', undefined)
  .factory('Socket', function ($rootScope, $stateParams){
    var socket = io.connect(window.rezflow.socket, {'transports': ['websocket', 'polling'] });
    return {
      on: function(eventName, callback) {
        socket.on(eventName, function() {
          var args = arguments;
          $rootScope.$apply(function() {
            callback.apply(socket, args);
          });
        });
      },
      emit: function(eventName, data, callback) {
        if($rootScope.user !== undefined) {
          var user = _.pick($rootScope.user, ['name', 'api_key', 'email', '_id']);
          var company = $rootScope.user && $rootScope.user.activeCompany ? _.pick($rootScope.user.activeCompany(), ['_id', 'name', 'nickname']) : undefined;
        }
        data = angular.extend({}, { payload: data, user: user, company: company, from: $stateParams });
        socket.emit(eventName, data, function() {
          var args = arguments;
          $rootScope.$apply(function() {
            if (callback) {
              callback.apply(socket, args);
            }
          });
        });
      }
    };

    });
