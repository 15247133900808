String.prototype.toObject = function( obj, value ) {
  var names = this.split('.');
  // If a value is given, remove the last name and keep it for later:
  var lastName = arguments.length === 2 ? names.pop() : false;
  // Walk the hierarchy, creating new objects where needed.
  // If the lastName was removed, then the last object is not set yet:
  for( var i = 0; i < names.length; i++ ) {
    obj = obj[ names[i] ] = obj[ names[i] ] || {};
  }
  // If a value was given, set it to the last name:
  if( lastName ) obj = obj[ lastName ] = value;
  // Return the last object in the hierarchy:
  return obj;
};
