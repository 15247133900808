if(angular.isUndefined(window.Stripe)){
  throw new Error('Please be sure to include the Stripe.js library: https://js.stripe.com/v2/');
}

/* @ngInject */
function MainFactory($rootScope) {
  // Stripe.setPublishableKey($rootScope.user.activeCompany().account.keys.public);
  Stripe.setPublishableKey(window.rezflow.stripe.pub_key);
  return Stripe;
}

/* @ngInject */
function StripeCard(Stripe) {

  var today = new Date();


  function card(options) {

    var options = options || {};

    if (options.key) this.key = options.key;

    this.errors = {}; // will be filled when validation issues are met by setters
    this.cardType = null; // will set after setting card number

    this.number = this.Number(options.number) || null;
    this.exp_month = this.expirationMonth(options.exp_month) || null;
    this.exp_year = this.expirationYear(options.exp_year) || null;

    // Optional
    this.name = options.name || null;
    this.cvc = options.cvc || null;
    this.address_zip = options.address_zip || null;
    this.address_line1 = options.address_line1 || null;
    this.address_line2 = options.address_line2 || null;
    this.address_city = options.address_city || null;
    this.address_state = options.address_state || null;
    this.address_country = options.address_country || null;

    return this;

  }

  card.prototype._error = function (key, details) {
    this.errors[key] = details;
  };

  card.prototype.Create = function (key, Success, Failure) {

    if (typeof key == 'function') {
      Failure = Success;
      Success = key;
    } else {
      this.key = key;
    }

    var self = this;

    if (this.key) Stripe.setPublishableKey(this.key);

    Stripe.card.createToken(self, function (status, response) {
      if (response.error) return Failure(response.error, status);
      return Success(response, status);
    });
  }

  card.prototype.type = function (number) {
    var self = this;
    if (number) {
      this.cardType = Stripe.card.cardType(number)
    } else {
      this.cardType = Stripe.card.cardType(this.number)
    }
    return this.cardType;
  }

  card.prototype.Number = function (number) {
    if (number) {
      if (Stripe.card.validateCardNumber(number)) {
        this.number = number;
        this.cardType = Stripe.card.cardType(this.number);
        delete this.errors['number'];
      } else {

        this._error('number', {
          value: number,
          message: 'invalid card number'
        });

        return false;

      }
    }
    return this.number;
  }

  card.prototype.expirationMonth = function (month) {
    if (month) {
      if (month <= 12) {
        this.exp_month = month;
        delete this.errors['exp_month'];
      } else {

        this._error('exp_month', {
          value: month,
          message: 'Invalid expiration month, should be a number between or including 1 and 12'
        });

        return false;

      }
    }
    return this.exp_month;
  }

  /**
   * Getter/Setter for card expiration year.
   * Does basic validation (ensures year is greater than or equal to this year)
   * @param  {Number} year Year on card (optional)
   * @return {Number}      Year attached to object
   */
  card.prototype.expirationYear = function (year) {
    if (year) {
      year = year.toString().length < 4 ? 2000 + parseInt(year) : year;
      if (year >= today.getFullYear()) {
        this.exp_year = year;
        delete this.errors['exp_year'];
      } else {

        this._error('exp_year', {
          value: year,
          message: 'Invalid expiration year, looks to be in the past'
        });

        return false;
      }
    }
    return this.exp_year;
  }

  card.prototype.CVC = function (cvc) {
    if (cvc) {
      if (Stripe.card.validateCVC(cvc)) {
        this.cvc = cvc;
        delete this.errors['cvc'];
      } else {

        this._error('cvc', {
          value: cvc,
          message: 'invalid cvc code'
        });

        return false;

      }
    }
    return this.cvc;
  }


  return card

}

/* @ngInject */
function StripeBank(Stripe) {

  var today = new Date();

  function bank(options) {

    var options = options || {};

    if (options.key) this.key = options.key;

    this.errors = {}; // will be filled when validation issues are met by setters
    this.country = options.country || 'US';

    this.currency = options.currency || 'USD';
    this.routing_number = options.routing_number || null
    this.account_number = options.account_number || null;

    return this;

  }

  bank.prototype.Create = function (key, Success, Failure) {

    if (typeof key == 'function') {
      Failure = Success;
      Success = key;
    } else {
      this.key = key;
    }

    var self = this;

    if (this.key) Stripe.setPublishableKey(this.key);

    var send = JSON.parse(JSON.stringify(angular.copy(self)));

    delete send.errors;
    delete send.key;

    Stripe.bankAccount.createToken(send, function (status, response) {
      if (response.error) return Function(response.error, status);
      return Success(response, status);
    });

  }

  return bank

}

var StripeVar = angular.module('stripe', [])
  .factory('Stripe', MainFactory)
  .factory('StripeCard', StripeCard)
  .factory('StripeBank', StripeBank);
