angular.module('ui-template', ['template/datepicker/day.html', 'template/datepicker/month.html', 'template/datepicker/year.html', 'template/rating/rating.html']);

angular.module("template/datepicker/day.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("template/datepicker/day.html",
    "<table class=\"table table-condensed\" role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th>\n" +
    "        {{ }}\n" +
    "        <button type=\"button\" class=\"btn btn-link btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\">\n" +
    "          <i class=\"fa fa-chevron-left\"></i>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "      <th colspan=\"{{::5 + showWeeks}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-link btn-sm\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" style=\"width:100%;\"><strong>{{title}}</strong></button></th>\n" +
    "      <th>\n" +
    "        <button type=\"button\" class=\"btn btn-link btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\">\n" +
    "          <i class=\"fa fa-chevron-right\"></i>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "    </tr>\n" +
    "    <tr>\n" +
    "      <th ng-if=\"showWeeks\" class=\"text-center\"></th>\n" +
    "      <th ng-repeat=\"label in ::labels track by $index\" class=\"text-center\"><small aria-label=\"{{::label.full}}\">{{::label.abbr}}</small></th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr ng-repeat=\"row in rows track by $index\">\n" +
    "      <td ng-if=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td>\n" +
    "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\">\n" +
    "        <button type=\"button\" style=\"width:100%;\" class=\"btn btn-link btn-sm\" ng-class=\"dayBtnClass(dt)\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\">\n" +
    "          <span ng-class=\"::{'text-muted': dt.secondary, 'text-info': dt.current }\">{{::dt.label}}</span>\n" +
    "        </button>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>");
}]);

angular.module("template/datepicker/month.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("template/datepicker/month.html",
    "<table class=\"table table-condensed\" role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th>\n" +
    "        <button type=\"button\" class=\"btn btn-link btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\">\n" +
    "          <i class=\"fa fa-chevron-left\"></i>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "      <th>\n" +
    "        <button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-link btn-sm\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" style=\"width:100%;\">\n" +
    "          <strong>{{title}}</strong>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "      <th>\n" +
    "        <button type=\"button\" class=\"btn btn-link btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\">\n" +
    "          <i class=\"fa fa-chevron-right\"></i>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr ng-repeat=\"row in rows track by $index\">\n" +
    "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\">\n" +
    "        <button type=\"button\" style=\"width:100%;\" class=\"btn btn-link\" \n" +
    "          ng-class=\"{'bg-info': dt.selected, active: isActive(dt)}\" \n" +
    "          ng-click=\"select(dt.date)\" \n" +
    "          ng-disabled=\"dt.disabled\" \n" +
    "          tabindex=\"-1\">\n" +
    "            <span>{{::dt.label}}</span>\n" +
    "          </button>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>");
}]);

angular.module("template/datepicker/year.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("template/datepicker/year.html",
    "<table class=\"table table-condensed\" role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th>\n" +
    "        <button type=\"button\" class=\"btn btn-link btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\">\n" +
    "          <i class=\"fa fa-chevron-left\"></i>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "      <th colspan=\"3\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-link btn-sm\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" style=\"width:100%;\"><strong>{{title}}</strong></button></th>\n" +
    "      <th>\n" +
    "        <button type=\"button\" class=\"btn btn-link btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\">\n" +
    "          <i class=\"fa fa-chevron-right\"></i>\n" +
    "        </button>\n" +
    "      </th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr ng-repeat=\"row in rows track by $index\">\n" +
    "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\">\n" +
    "        <button type=\"button\" style=\"width:100%;\" class=\"btn btn-link\" ng-class=\"{'bg-info': dt.selected, active: isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span>{{::dt.label}}</span></button>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>");
}]);

angular.module("template/rating/rating.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("template/rating/rating.html",
    "<span ng-mouseleave=\"reset()\" ng-keydown=\"onKeydown($event)\" tabindex=\"0\" role=\"slider\" aria-valuemin=\"0\" aria-valuemax=\"{{range.length}}\" aria-valuenow=\"{{value}}\">\n" +
    "    <span ng-repeat-start=\"r in range track by $index\" class=\"sr-only\">({{ $index < value ? '*' : ' ' }})</span>\n" +
    "    <i ng-repeat-end ng-mouseenter=\"enter($index + 1)\" ng-click=\"rate($index + 1)\" class=\"fa\" ng-class=\"$index < value && (r.stateOn || 'glyphicon-star') || (r.stateOff || 'glyphicon-star-empty')\" ng-attr-title=\"{{r.title}}\" aria-valuetext=\"{{r.title}}\"></i>\n" +
    "</span>");
}]);
